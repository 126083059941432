<template>
    <div class="sellPoint-invoice">
        <!--page details invoice | new invoice-->
        <ValidationObserver ref="form">
            <b-row>
                <b-col cols="12" lg="9">
                    <b-card>
                        <!--add header card to serialNum, Date-->
                        <b-card-header>
                            <div>
                                <div class="d-flex mb-2 align-items-center">
                                    <h3 class="text-primary pr-2">
                                        <strong>فيثاكورس</strong>
                                    </h3>
                                </div>
                            </div>
                            <div>
                                <ek-input-text
                                    v-if="invoicid == 0"
                                    readonly
                                    name="number"
                                    label="رقم الفاتورة"
                                    :value="sellpointUnpaid.invoiceSerialNumber"
                                >
                                </ek-input-text>
                                <ek-input-text
                                    v-if="invoicid != 0"
                                    readonly
                                    name="number"
                                    label="رقم الفاتورة"
                                    :value="sellPointDto.serialNumber"
                                >
                                </ek-input-text>

                                <ek-input-text
                                    v-if="invoicid == 0"
                                    name="dateInvoice"
                                    label="تاريخ الفاتورة"
                                    :value="new Date().toLocaleDateString()"
                                >
                                </ek-input-text>
                                <ek-input-text
                                    v-if="invoicid != 0"
                                    name="dateInvoice"
                                    label="تاريخ الفاتورة"
                                    :value="formDate(sellPointDto.invoiceDate)"
                                >
                                </ek-input-text>
                            </div>
                        </b-card-header>

                        <hr />
                        <!--add Answers -->
                        <b-card-body>
                            <!--if no code Unpaid-->
                            <div
                                class="d-flex justify-content-center"
                                v-if="sellpointUnpaid.codes == ''"
                            >
                                <h4>لا يوجد أكواد</h4>
                            </div>
                            <!-- All Code is unPaid-->
                            <div
                                v-for="(code, index) in sellpointUnpaid.codes"
                                :key="index"
                                class="d-flex"
                            >
                                <b-row>
                                    <b-col lg="3" cols="3">
                                        <b-form-group
                                            v-if="invoicid == 0"
                                            name="code"
                                            label="الكود"
                                        >
                                            <b-form-input
                                                name="code"
                                                id="item-name"
                                                type="text"
                                                plaintext
                                                v-model="code.activationCode"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="2" cols="2">
                                        <b-form-group
                                            v-if="invoicid == 0"
                                            name="date"
                                            label="التاريخ"
                                            label-for="date"
                                        >
                                            <b-form-input
                                                name="date"
                                                plaintext
                                                :value="
                                                    formDt(code.dateCreated)
                                                "
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="2" cols="2">
                                        <b-form-group
                                            v-if="invoicid == 0"
                                            :disabled="!ready"
                                            name="num"
                                            label="الحسم "
                                        >
                                            <b-form-input
                                                name="num"
                                                v-model="code.discount"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="2" cols="2">
                                        <b-form-group
                                            v-if="invoicid == 0"
                                            :disabled="!ready"
                                            name="unit"
                                            label="النسبة"
                                        >
                                            <b-form-input
                                                name="unit"
                                                v-model="code.sellPointRate"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="2" cols="2">
                                        <b-form-group
                                            v-if="invoicid == 0"
                                            name="cost"
                                            label="الكلفة"
                                        >
                                            <b-form-input
                                                name="cost"
                                                plaintext
                                                v-model="code.price"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="1" cols="1" v-if="invoicid == 0">
                                        <b-button
                                            variant="wight"
                                            @click="deleteCode(index)"
                                        >
                                            <svg
                                                style="cursor: pointer"
                                                width="25"
                                                height="25"
                                                viewBox="0 0 19 19"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                                                    stroke="#616060"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M11.875 7.125L7.125 11.875"
                                                    stroke="#616060"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.125 7.125L11.875 11.875"
                                                    stroke="#616060"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>
                            <!--code is Paid-->
                            <div
                                v-for="code in sellPointDto.codes"
                                :key="code.id"
                                class="d-flex"
                            >
                                <b-row>
                                    <b-col lg="3" cols="3">
                                        <b-form-group
                                            v-if="invoicid != 0"
                                            name="code"
                                            label="الكود"
                                        >
                                            <b-form-input
                                                name="code"
                                                id="item-name"
                                                type="text"
                                                plaintext
                                                v-model="code.activationCode"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="3" cols="3">
                                        <b-form-group
                                            v-if="invoicid != 0"
                                            name="date"
                                            label="التاريخ"
                                            label-for="date"
                                        >
                                            <b-form-input
                                                name="date"
                                                plaintext
                                                :value="
                                                    formDat(code.dateCreated)
                                                "
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="2" cols="2">
                                        <b-form-group
                                            v-if="invoicid != 0"
                                            :disabled="!ready"
                                            name="num"
                                            label="الحسم "
                                        >
                                            <b-form-input
                                                name="num"
                                                v-model="code.discount"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="2" cols="2">
                                        <b-form-group
                                            v-if="invoicid != 0"
                                            :disabled="!ready"
                                            name="unit"
                                            label="النسبة"
                                        >
                                            <b-form-input
                                                name="unit"
                                                v-model="code.sellPointRate"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="2" cols="2">
                                        <b-form-group
                                            v-if="invoicid != 0"
                                            name="cost"
                                            label="الكلفة"
                                        >
                                            <b-form-input
                                                name="cost"
                                                plaintext
                                                v-model="code.price"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card-body>
                        <b-card-footer>
                            <!--add footer to paid , note-->
                            <b-row>
                                <b-col lg="7" cols="12">
                                    <label>الملاحظات</label>
                                    <ek-input-textarea
                                        id="note"
                                        rows="6"
                                        v-model="sellPointDto.note"
                                        name="note"
                                        placeholder="اضف الملاحظات"
                                    >
                                    </ek-input-textarea>
                                </b-col>
                                <b-col lg="5" cols="12">
                                    <b-col cols="12">
                                        <ul class="list-unstyled p-0 m-0">
                                            <li
                                                class="d-flex pb-1 pt-2 justify-content-between align-items-center"
                                            >
                                                <div>عدد الأكواد</div>
                                                <div v-if="invoicid == 0">
                                                    {{
                                                        sellpointUnpaid.codes
                                                            .length
                                                    }}
                                                </div>
                                                <div v-if="invoicid != 0">
                                                    {{
                                                        sellPointDto.codes
                                                            .length
                                                    }}
                                                </div>
                                            </li>
                                            <li
                                                v-if="invoicid == 0"
                                                class="d-flex pb-1 pt-2 justify-content-between align-items-center"
                                            >
                                                <div
                                                    v-if="
                                                        sellpointUnpaid.dept > 0
                                                    "
                                                >
                                                    مبلغ متبقي من كشوفات سابقة
                                                </div>
                                                <div
                                                    v-else-if="
                                                        sellpointUnpaid.dept < 0
                                                    "
                                                >
                                                    الرصيد الموجود
                                                </div>
                                                <div
                                                    v-if="
                                                        sellpointUnpaid.dept ==
                                                        0
                                                    "
                                                ></div>
                                                <div
                                                    v-if="
                                                        sellpointUnpaid.dept !==
                                                        0
                                                    "
                                                    :style="{
                                                        color:
                                                            sellpointUnpaid.dept >
                                                            0
                                                                ? 'red'
                                                                : sellpointUnpaid.dept <
                                                                  0
                                                                ? 'green'
                                                                : 'black',
                                                    }"
                                                >
                                                    {{ sellpointUnpaid.dept }}
                                                </div>
                                            </li>
                                            <li
                                                class="d-flex pt-1 justify-content-between align-items-center"
                                            >
                                                <div>الكلفة</div>
                                                <div v-if="invoicid == 0">
                                                    {{
                                                        sellpointUnpaid.codes.reduce(
                                                            (pre, cur) =>
                                                                pre + cur.price,
                                                            0
                                                        )
                                                    }}
                                                    ل.س
                                                </div>
                                                <div v-if="invoicid != 0">
                                                    {{
                                                        (
                                                            sellPointDto.codes ||
                                                            []
                                                        ).reduce(
                                                            (pre, cur) =>
                                                                pre + cur.price,
                                                            0
                                                        )
                                                    }}
                                                    ل.س
                                                </div>
                                            </li>
                                        </ul>
                                    </b-col>
                                    <b-col cols="12">
                                        <hr class="d-none d-lg-block" />
                                    </b-col>
                                    <b-col cols="12">
                                        <ul class="list-unstyled p-0">
                                            <li
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <div>المستحق للشركة</div>
                                                <div v-if="invoicid == 0">
                                                    {{
                                                        sellpointUnpaid.codes.reduce(
                                                            (pre, cur) =>
                                                                pre + cur.paid,
                                                            0
                                                        )
                                                    }}
                                                    ل.س
                                                </div>
                                                <div v-if="invoicid != 0">
                                                    {{
                                                        (
                                                            sellPointDto.codes ||
                                                            []
                                                        ).reduce(
                                                            (pre, cur) =>
                                                                pre +
                                                                cur.profits,
                                                            0
                                                        )
                                                    }}
                                                    ل.س
                                                </div>
                                            </li>
                                        </ul>
                                        <div>
                                            <b-input-group class="d-flex">
                                                <b-input-group-prepend is-text>
                                                    المدفوع
                                                </b-input-group-prepend>

                                                <b-form-input
                                                    type="number"
                                                    :placeholder="
                                                        sellpointUnpaid.dept > 0
                                                            ? sellpointUnpaid.dept +
                                                              sellpointUnpaid.codes.reduce(
                                                                  (pre, cur) =>
                                                                      pre +
                                                                      cur.paid,
                                                                  0
                                                              )
                                                            : ' المبلغ المدفوع'
                                                    "
                                                    v-model="sellPointDto.paid"
                                                >
                                                </b-form-input>
                                                <b-input-group-append is-text>
                                                    ل.س
                                                </b-input-group-append>
                                            </b-input-group>
                                        </div>
                                        <div>
                                            <li
                                                class="d-flex pt-1 justify-content-between align-items-center"
                                            >
                                                <div
                                                    v-if="
                                                        sellPointDto.paid >
                                                        (
                                                            sellpointUnpaid.codes ||
                                                            []
                                                        ).reduce(
                                                            (pre, cur) =>
                                                                pre + cur.paid,
                                                            0
                                                        )
                                                    "
                                                >
                                                    مبلغ إضافي
                                                </div>
                                                <div v-else>المتبقي</div>

                                                <div
                                                    v-if="invoicid == 0"
                                                    :style="{
                                                        color:
                                                            sellpointUnpaid.codes.reduce(
                                                                (pre, cur) =>
                                                                    pre +
                                                                    cur.paid,
                                                                0
                                                            ) -
                                                                sellPointDto.paid <
                                                            0
                                                                ? 'green'
                                                                : 'red',
                                                    }"
                                                >
                                                    {{
                                                        Math.abs(
                                                            (
                                                                sellpointUnpaid.codes ||
                                                                []
                                                            ).reduce(
                                                                (pre, cur) =>
                                                                    pre +
                                                                    cur.paid,
                                                                0
                                                            ) -
                                                                sellPointDto.paid
                                                        )
                                                    }}
                                                    ل.س
                                                </div>
                                                <div
                                                    v-if="invoicid != 0"
                                                    :style="{
                                                        color:
                                                            (
                                                                sellpointUnpaid.codes ||
                                                                []
                                                            ).reduce(
                                                                (pre, cur) =>
                                                                    pre +
                                                                    cur.paid,
                                                                0
                                                            ) -
                                                                sellPointDto.paid <
                                                            0
                                                                ? 'green'
                                                                : 'red',
                                                    }"
                                                >
                                                    {{
                                                        Math.abs(
                                                            (
                                                                sellpointUnpaid.codes ||
                                                                []
                                                            ).reduce(
                                                                (pre, cur) =>
                                                                    pre +
                                                                    cur.paid,
                                                                0
                                                            ) -
                                                                sellPointDto.paid
                                                        )
                                                    }}
                                                    ل.س
                                                </div>
                                            </li>
                                        </div>
                                    </b-col>
                                </b-col>
                            </b-row>
                        </b-card-footer>
                    </b-card>
                </b-col>
                <b-col cols="12" lg="3" class="print">
                    <!--add button to save , print-->
                    <b-card>
                        <b-button
                            v-if="invoicid == 0"
                            variant="primary"
                            block
                            @click="AddInvoice"
                        >
                            حفظ
                        </b-button>
                        <b-button
                            v-if="invoicid == 0"
                            variant="outline-primary"
                            block
                            @click="print"
                        >
                            طباعة
                        </b-button>
                        <b-button
                            v-if="invoicid == 0"
                            variant="outline-primary"
                            block
                            @click="$router.go(-1)"
                            >عودة
                        </b-button>
                        <b-button
                            v-if="invoicid != 0"
                            variant="primary"
                            block
                            @click="print"
                        >
                            طباعة
                        </b-button>
                        <b-button
                            v-if="invoicid != 0"
                            variant="outline-primary"
                            block
                            @click="deleteInvoice(id)"
                        >
                            حذف
                        </b-button>
                    </b-card>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { BToast } from "bootstrap-vue";

export default {
    props: ["invoicid", "id"],
    computed: {
        ...mapState({
            sellpointUnpaid: (state) => state.invoiceSellPoint.sellpointUnpaid,
            sellpointsDetails: ({ invoiceSellPoint }) =>
                invoiceSellPoint.sellpointsDetails,
        }),
    },
    data() {
        return {
            sellPointDto: {
                id: "",
                sellPointId: "",
                invoiceDate: "",
                dateCreated: Date.now(),
                codes: [
                    {
                        id: "",
                        activationCode: "",
                        dateCreated: "",
                        sellPointRate: "",
                        discount: "",
                        price: "",
                    },
                ],
                codesIds: [],
                note: "",
            },
            sellPointList: [],
        };
    },
    methods: {
        ...mapActions([
            "getSellPointDetails",
            "getUnpaidSellPointList",
            "addInvoiceSellPoint",
            "deleteInvoice",
        ]),
        print() {
            window.print();
        },
        formDate(invoiceDate) {
            let date = `${new Date(invoiceDate).toLocaleDateString()}`;
            return date;
        },
        formDat(dateCreated) {
            let date = `${new Date(dateCreated).toLocaleDateString()}`;
            return date;
        },
        formDt(dateCreated) {
            let date = `${new Date(dateCreated).toLocaleDateString()}`;
            return date;
        },
        deleteCode(index) {
            this.sellpointUnpaid.codes.splice(index, 1);
            this.sellPointDto.codesIds.splice(index, 1);
            this.sellPointDto.codeCount--;
        },
        AddInvoice() {
            this.$refs.form.validate().then((succ) => {
                if (
                    succ &&
                    this.sellpointUnpaid.codes.length > 0 &&
                    this.sellPointDto.paid
                ) {
                    this.addInvoiceSellPoint(this.sellPointDto).then(
                        this.sellPointList.push(this.sellPointDto)
                    );
                } else {
                    const bToast = new BToast();
                    bToast.$bvToast.toast(
                        "   تأكد من إدخال قيمة الدفع و وجود اكواد للمحاسبة عليها ",
                        {
                            title: "  لا يمكنك حفظ افاتورة  ",
                            variant: "danger",
                            toaster: "b-toaster-bottom-right",
                            solid: true,
                            autoHideDelay: 5000,
                            appendToast: true,
                        }
                    );
                }
            });
        },
    },
    created() {
        this.sellPointDto.sellPointId = this.id;
        if (this.invoicid != 0) {
            this.getSellPointDetails(this.invoicid).then((res) => {
                this.sellPointDto = { ...res };
            });
        } else {
            this.getUnpaidSellPointList(this.id).then((res) => {
                this.sellPointDto.codesIds = res.codes.map((el) => el.id);
            });
        }
    },
};
</script>
<style>
@media print {
    .print {
        display: none;
    }

    .main-menu {
        display: none !important;
    }

    .app-content {
        margin: 0 !important;
    }

    .footer {
        display: none !important;
    }

    .navbar {
        display: none !important;
    }

    .breadcrumb-item {
        display: none !important;
    }
}
</style>
